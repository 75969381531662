<template>
  <div v-if="summForQr != ''">
    <loader v-show="loading"></loader>
    <div v-show="!loading">
      <p id="titleQr"><span>Для оплаты по QR-коду, укажите сумму платежа</span><span> и подтвердите создание нового
        QR-кода</span></p>
      <div class="flex">
        <baseinput
          :label="'Сумма платежа'"
          :errMsg="errMsg"
          v-model="summForQr"
        ></baseinput>
        <button v-on:click="newQr">Создать новый QR</button>
      </div>
      <div v-if="QR.length > 0">
        <p v-if="defaultQr"><i>Данный QR сформирован по сумме рекомендованного платежа</i></p>
        <img :src="QR" alt="">
      </div>

    </div>
  </div>
</template>

<script>

  // http://ws.msk.finmoll.com:8736/Payment/GetQrCode/GetQrCode/508363?PaySum=12  8fsBkR0ZG+fWsAAAAASUVORK5CYII
  import {mapGetters} from 'vuex';
  export default {
    name: "qr",
    data(){
      return {
        QR:'',
        summForQr:'',
        defaultQr:true,
        loading:false,
        errMsg:''
      }
    },
    components:{
      baseinput:()=>import('@/components/Form/element/baseinput'),
      loader: () => import('@/components/other/loader'),
    },
    methods:{
      newQr(){
        this.defaultQr = false;
        this.loading = true;
        if (isNaN(Math.ceil(this.summForQr))) {
          this.errMsg = 'Не корректная сумма';
          this.loading = false;
        } else{
          this.errMsg = '';
          this.qrPromiseResult();
        }

      },
      getQrApi(){
        return new Promise((resolve, reject) => {
          let sendObject = {
            action:'getQr',
            type:'dlPayment',
            demand: parseInt(this.activeDealData('DealInfo').DlCode, 10),
          };
          if (this.summForQr.toString() !== this.activeDealData('DealInfo').CurPay.toString()) {
            sendObject['PaySum'] = Math.ceil(this.summForQr);
          }
          this.$myApi.singleRequest(sendObject).then((res)=>{
            resolve(res.qrImage);
          }).catch((res)=>{
            console.log(res);
            reject();
          })
        });
      },
      qrPromiseResult(){
        this.getQrApi()
        .then((res)=> {
          if (res.status != undefined && res.status == 400) {
            alert('QR-код временно не доступен, попробуйте позже');
          } else {
            this.QR = 'data:image/jpeg;base64,' + res;
            localStorage.setItem('qr', this.QR);
            localStorage.setItem('qrSumm', this.summForQr);
          }
        })
        .catch(()=>{
          alert('QR-код временно не доступен, попробуйте позже');
        }).finally(()=>{
          this.loading = false;
        })
      },
      qrAndCashe(){
        this.qrPromiseResult();
        //для теста ниже выключили
        // // проверяем есть ли уже созданный qr
        // if (localStorage.getItem('qr') == undefined) {
        //   //в кеш нет qr кода, запускаем новый
        //   this.qrPromiseResult();
        // } else if (localStorage.getItem('qrSumm') != undefined
        //           && this.summForQr == localStorage.getItem('qrSumm')){
        //   //в кеш есть qr код, и сумма совпадает грузим из кеш
        //   this.QR = localStorage.getItem('qr');
        // } else {
        //   //в кеш есть qr код, и сумма НЕ совпадает, получаем новый
        //   this.qrPromiseResult();
        // }
      },
      tryQetQr(){
        //без DlCode создовать qr нет смысла
        if(this.activeDealData('DealInfo').DlCode != undefined) {
          this.summForQr = parseInt(this.activeDealData('DealInfo').CurPay.toString(),10);
          this.qrAndCashe();
        } else {
          //DlCode еще не сохранен в vuex, пробуем заного через 1.5секунды
          let self = this;
          setTimeout(()=>{
            self.tryQetQr();
          }, 1500);
        }
      }
    },
    mounted(){
      this.tryQetQr();
    },
    computed:{
      ...mapGetters([
        'activeDealData',
      ]),


      mutatedResults() {
        return this.activeDealData('DealInfo').DlCode;
      },
    },
    watch:{
      mutatedResults(){
        this.tryQetQr();
      }
    }
  }
</script>

<style scoped>
  .flex{flex-wrap: wrap}
  .flex > div{width: 100%}
  .flex > button{width: 100%}
  img{max-width: 90%;margin: 0 auto}
  /deep/ .peeek-loading{min-height: 300px;}
  @media screen and (min-width: 760px){


    img{max-width: 50%;}
  }
  @media screen and (min-width: 1200px){
    #titleQr span{display: block}
    .flex{flex-wrap: nowrap}
    .flex > div{width: 50%}
    .flex > button{width: 45%}
  }
</style>
